<template>
	<div>
		<h4 class="text-center my-3">Top winners</h4>

		<table class="table table-hover w-100">
			<thead class="font-weight-bold">
			<tr>
				<td scope="col">
					<span class="text-nowrap text-truncate w-100">#</span>
				</td>
				<td class="text-nowrap text-truncate" scope="col">
					<span class="text-nowrap text-truncate w-100">Hands</span>
				</td>
				<td class="text-nowrap text-truncate" scope="col">
					<span class="text-nowrap text-truncate w-100">Bet</span>
				</td>
				<td class="text-nowrap text-truncate" scope="col">
					<span class="text-nowrap text-truncate w-100">Win</span>
				</td>
				<td class="text-nowrap text-truncate" scope="col">
					<span class="text-nowrap text-truncate w-100">Net</span>
				</td>
				<td class="text-nowrap text-truncate" scope="col">
					<span class="text-nowrap text-truncate w-100">Max win</span>
				</td>
				<td class="text-nowrap text-truncate" scope="col">
					<span class="text-nowrap text-truncate w-100">Max bal.</span>
				</td>
				<td class="text-nowrap text-truncate" scope="col">
					<span class="text-nowrap text-truncate w-100">Final bal.</span>
				</td>
				<td class="text-nowrap text-truncate" scope="col">
					<span class="text-nowrap text-truncate w-100">Bonus</span>
				</td>
				<td class="text-nowrap text-truncate" scope="col">
					<span class="text-nowrap text-truncate w-100">Freespins</span>
				</td>
				<td class="text-nowrap text-truncate" scope="col">
					<span class="text-nowrap text-truncate w-100">RTP</span>
				</td>
			</tr>
			</thead>

			<tr v-for="row in rows" :key="row.PlayerId" @click="showPlayerDetails(row)">
				<td>{{row.PlayerId}}</td>
				<td class="text-nowrap">{{row.TotalHands}}</td>
				<td class="text-nowrap">{{row.BetAmount | numeral('0,0')}} €</td>
				<td class="text-nowrap">{{row.WinAmount | numeral('0,0')}} €</td>
				<td class="text-nowrap">{{row.NetAmount | numeral('0,0')}} €</td>
				<td class="text-nowrap">{{row.MaxWinAmount | numeral('0,0')}} €</td>
				<td class="text-nowrap">{{row.MaxBalance | numeral('0,0')}} €</td>
				<td class="text-nowrap">{{row.Balance | numeral('0,0')}} €</td>
				<td class="text-center">
					<i class="fa fa-check text-success" v-if="row.BonusTriggered"></i>
					<i class="fa fa-times text-danger" v-else></i>
					{{row.BonusTriggeredCount > 0 ? row.BonusTriggeredCount : "-"}}
				</td>
				<td class="text-center">
					<i class="fa fa-check text-success" v-if="row.FreespinTriggered"></i>
					<i class="fa fa-times text-danger" v-else></i>
					{{row.FreespinTriggeredCount > 0 ? row.FreespinTriggeredCount : "-"}}
				</td>
				<td>{{(row.WinAmount / row.BetAmount) | numeral("0.00%")}}</td>
			</tr>
		</table>
	</div>
</template>

<script>
	import api from '@/api'
	import messageBox from '@/components/modals/player-balance-chart'

	export default {
		name: "top-players-table",
		props: {
			gameplayReportId: Number,
			totalPlayers: Number,
			ticker: Number
		},
		data() {
			return {
				rows: [],
				selectedPlayer: null
			}
		},
		async mounted() {
			this.update()
		},
		watch: {
			ticker() {
				this.update()
			}
		},
		methods: {
			showPlayerDetails(player) {
				messageBox(player)
			},
			async update() {
				this.rows = await api.GameplayReports.getTopPlayers(this.gameplayReportId)
			}
		}
	}
</script>

<style scoped>

</style>
