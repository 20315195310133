<template>
	<div>
		<fusioncharts
				type="column2d"
				width="100%"
				height="300"
				dataFormat="json"
				:dataSource="dataSource"
				autoscale="1"
		></fusioncharts>
		<div class="d-block">
			<input type="checkbox" id="show-percentage" v-model="showPercentage">
			<label class="ml-2" for="show-percentage">Show percentage</label>
		</div>
	</div>
</template>

<script>
	export default {
		name: "distribution-chart",
		props: {
			distribution: Object,
			title: {
				type: String,
				default: ""
			}
		},
		data() {
			return {
				showPercentage: true
			}
		},
		computed: {
			dataSource() {
				return {
					chart: {
						caption: this.title,
						//subcaption: "In MMbbl = One Million barrels",
						xaxisname: "Awards",
						yaxisname: "Hit frequency",
						numbersuffix: this.showPercentage ? "%" : " hits",
						theme: "fusion",
						showValues: true,
						exportEnabled: true,
						exportFormats: "PNG=Export as High Quality Image|PDF=Export as Printable|XLSX=Export Chart Data",
						exportTargetWindow: "_self",
						exportFileName: this.title,
					},
					data: this.chartData
				}
			},
			chartData() {
				let data = []

				const total_awards = Object.values(this.distribution).reduce((c, d) => c + d, 0)

				for (let key in this.distribution) {

					let value = this.distribution[key]

					if (this.showPercentage) {
						value = (Math.round(this.distribution[key] / total_awards * 10000) / 100)
					}

					data.push({
						label: key,
						value: value
					})
				}

				return data
			},
			categoriesData() {
				let data = []

				for (let key in this.distribution) {
					data.push(key)
				}

				return data
			}
		}
	}
</script>

<style scoped>

</style>
