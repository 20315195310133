<template>
	<div class="row">
		<div class="col-auto" v-if="lastResult">
			<cage :config="config" class="w-100 d-inline-flex justify-content-center p-3" :class="{'bg-dark': winningPositions}" :winningPositions="winningPositions" :show-buttons="false" :value="lastResult.state.reels"></cage>
			<slot></slot>
		</div>
		<div class="col" v-if="lastResult">
			<last-win :config="config" :compact="true" v-model="selectedWin" :selected-index="selectedIndex" @mousehover="clearWinningAnimation"
					  @mouseout="setupWinningAnimation"
					  v-if="lastResult" :wins="lastResult.wins"></last-win>
		</div>
	</div>
</template>

<script>
	import Cage from "./cage";
	import LastWin from "./last-win";

	export default {
		name: "game-viewer",
		props: {
			lastResult: {
				type: Object,
				default: null
			},
			config: {
				type: Object
			}
		},
		async mounted() {
			this.setupWinningAnimation()
		},
		data() {
			return {
				selectedWin: null,
				selectedIndex: null,
				winningAnimationInterval: null
			}
		},
		methods: {
			clearWinningAnimation() {
				if (this.winningAnimationInterval !== null) {
					clearInterval(this.winningAnimationInterval)
				}
			},
			setupWinningAnimation() {

				this.clearWinningAnimation()

				if (this.lastResult && this.lastResult.wins.length > 1) {
					this.winningAnimationInterval = setInterval(() => {
						if (this.selectedIndex === null) {
							this.selectedIndex = 0;
						} else if (this.selectedIndex < this.lastResult.wins.length - 1) {
							this.selectedIndex++;
						} else {
							this.selectedIndex = null;
							this.selectedWin = null
						}

						if (this.selectedIndex !== null) {
							this.selectedWin = this.lastResult.wins.slice()[this.selectedIndex]
						}

					}, 1000 * 3)
				}

			},
		},
		computed: {
			winningPositions() {
				let positions = []

				if (this.selectedWin) {
					positions.push(...this.selectedWin.coords)
				}

				return positions;
			},
		},
		components: {LastWin, Cage}
	}
</script>

<style scoped>

</style>
