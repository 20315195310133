<template>
	<div class="message-box dialog-mask" @click="$close">
		<div class="dialog-content" @click.stop>
			<div class="dialog-body">
				<player-balance-chart :bet-unit="player.BetAmount / player.TotalHands" :title="`Player #${player.PlayerId} hands analysis`" :history="player.BalanceHistory"></player-balance-chart>
			</div>
			<div class="dialog-body">
				<distribution-chart class="w-100" :title="`Player #${player.PlayerId} award distribution chart`" :distribution="player.PrizesDistribution"></distribution-chart>
			</div>
			<footer class="d-flex">
				<button class="btn btn-success" type="text" size="mini" @click="$close">OK</button>
			</footer>
		</div>
	</div>
</template>

<style lang="scss" scoped>
	@import "@/assets/style/_palette";

	footer {
		border-top: 1px solid lighten($color-grey, 20%);
	}

	.dialog-content{
		width: 90vw;
	}
</style>
<script>
	import PlayerBalanceChart from "../reports/player-balance-chart";
	import DistributionChart from "../reports/distribution-chart";
	export default {
		components: {DistributionChart, PlayerBalanceChart}
	}
</script>
