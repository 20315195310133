<template>
	<div class="d-block w-100 position-relative">
		<fusioncharts
				type="scrollcombidy2d"
				width="100%"
				height="400"
				dataFormat="json"
				:dataSource="dataSource"
		></fusioncharts>
	</div>
</template>

<script>
	export default {
		name: "player-balance-chart",
		props: {
			history: Array,
			title: String,
			betUnit: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {}
		},
		computed: {
			dataSource() {
				return {
					chart: {
						caption: this.title,
						//subcaption: "By province",
						xaxisname: 'Hands',
						yaxisname: "Balance",
						syaxisname: "RTP",
						labeldisplay: "rotate",
						snumbersuffix: "%",
						scrollheight: "15",
						//numvisibleplot: "50",
						drawcrossline: "1",
						theme: "fusion",
						showTick: 0,
						showTickMarks: 0,
						showTickValues: 0,
						drawAnchors: "0",
						multicanvas: false,
						"exportEnabled": "1",
						"exportFormats": "PNG=Export as High Quality Image|PDF=Export as Printable|XLSX=Export Chart Data",
						"exportTargetWindow": "_self",
						"exportFileName": "Player Analysis",
						exportFileName: this.title,
					},
					categories: [
						{
							category: this.categoriesData
						}
					],
					dataset: [
						{
							seriesname: "Balance",
							plottooltext: "$dataValue coins",
							renderas: "line",
							//numberscalevalue: "100",
							data: this.chartData,
							"showValues": "1",
						},
						{
							seriesname: "RTP",
							parentyaxis: "S",
							renderas: "area",
							plottooltext: "$dataValue RTP",
							data: this.rtpChartData,
							"showBorder": "1",
						}
					],
					"trendlines": [{
						"line": [
							{
								parentYAxis: 'P',
								//displayvalue: "Max balance",
								isTrendZone: "1",
								startvalue: this.history.reduce((c, h) => Math.max(c, h), 0),
								//"endvalue": this.history.reduce((c, h) => Math.max(c, h), 0),
								color: "rgba(255, 0, 0, 1.0)",
								"dashed": "1",
								"dashLen": "4",
								"dashGap": "2"
							}
						]
					},
						{
							line: [
								{
									"isTrendZone": "1",
									"startvalue": this.history[0],
									color: "rgba(148, 233, 174, 1.0)",
									"dashed": "1",
									"dashLen": "4",
									"dashGap": "2"
								}
							]
						}]
				}
			},
			chartData() {
				let data = []
				let last_balance = parseInt(this.history[0])
				this.history.forEach((balance, handId) => {
							data.push({
								value: `${balance}`,
								"displayValue": last_balance < parseInt(balance) ? `+${parseInt(balance)-last_balance}` : ' '
							})
							last_balance = balance
						}
				)
				return data
			},
			rtpChartData() {
				let total_bet = 0
				let total_win = 0
				let last_balance = this.history[0]

				let data = [{value: 0}]
				this.history.slice(1).forEach((balance, handId) => {
					let win_amount = balance - last_balance
					last_balance = balance

					total_bet += this.betUnit
					total_win += this.betUnit + win_amount

					data.push({value: (total_win / total_bet * 100).toFixed(2)})
				})

				return data
			},
			categoriesData() {
				let data = []
				this.history.forEach((balance, handId) => data.push({
					label: `${handId}`
				}))
				return data
			}
		},
		components: {
			FusionCharts
		},
	}
</script>

<style scoped>

</style>
