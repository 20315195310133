<template>
	<div v-if="rows">
		<h4 class="text-center my-3">Player Top Award Hit</h4>
		<table class="table">
			<tr v-for="(players, time) in rows">
				<td>{{time}} €</td>
				<td>
					<div class="d-flex align-items-center">
						<div v-text="players" style="width: 100px" class="mr-2 text-right"></div>
						<div class="w-100">
							<div class="progress" v-if="totalPlayers > 0">
								<div class="progress-bar"
									 :style="{width: `${(players / totalPlayers * 100).toFixed(2)}%`}"></div>
							</div>
						</div>
						<div style="width: 100px" class="text-right">{{(players / totalPlayers) | numeral('0.00%') }}
						</div>
					</div>
				</td>
			</tr>
		</table>
		<div class="text-muted text-small">
			<ul>
				<li>This chart gives you more information about the volatility</li>
				<li>It tells you how many players hit large awards</li>
			</ul>
			<div>If not enough players are hitting big awards, it can indicate your volatility is too low or your pay
				table and reels are not well balanced.
			</div>
		</div>
	</div>
</template>

<script>
	import api from '@/api'

	export default {
		name: "gameplay-players-top-award-hit",
		props: {
			gameplayReportId: Number,
			totalPlayers: Number,
			ticker: Number
		},
		data() {
			return {
				rows: null
			}
		},
		async mounted() {
			this.update()
		},
		watch: {
			ticker() {
				this.update()
			}
		},
		methods: {
			async update() {
				this.rows = await api.GameplayReports.getPlayersTopAwardHit(this.gameplayReportId)
			}
		}
	}
</script>

<style scoped>

</style>
