<template>
	<div v-if="rows">
		<h4 class="text-center my-3">Winners RTP Distribution</h4>
		<table class="table">
			<tr v-for="(players, time) in rows">
				<td v-text="time.replace('_', ' ')"></td>
				<td>
					<div class="d-flex align-items-center">
						<div v-text="players" style="width: 100px" class="mr-2 text-right"></div>
						<div class="w-100">
							<div class="progress" v-if="totalPlayers > 0">
								<div class="progress-bar"
									 :style="{width: `${(players / totalPlayers * 100).toFixed(2)}%`}"></div>
							</div>
						</div>
						<div style="width: 100px" class="text-right">{{(players / totalPlayers) | numeral('0.00%') }}
						</div>
					</div>
				</td>
			</tr>
		</table>
		<!--
		<div class="text-muted text-small">
			<ul>
				<li>This chart shows how long players last, on average</li>
				<li>Chart assumes 10 spins per minute</li>
			</ul>
			<div>If you lose most of your player in the first 5 minutes, then it doesn't matter what the feature do
				because nobody will ever see them.
			</div>
		</div>
		-->
	</div>
</template>

<script>
	import api from '@/api'

	export default {
		name: "players-winners-rtp-distribution",
		props: {
			gameplayReportId: Number,
			totalPlayers: Number,
			ticker: Number
		},
		data() {
			return {
				rows: null
			}
		},
		async mounted() {
			this.update()
		},
		watch: {
			ticker() {
				this.update()
			}
		},
		methods: {
			async update() {
				this.rows = await api.GameplayReports.getPlayersWinnersRtpDistribution(this.gameplayReportId)
			}
		}
	}
</script>

<style scoped>

</style>
